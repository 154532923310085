import React from 'react';
import { Helmet } from 'react-helmet';
import { booksMeta } from '../lib/booksMeta';
import Head from '../components/head'
import Layout from '../components/layout';
import Hero from '../components/hero';
import LeadingQuote from '../components/leadingQuote';
// import BookExtract from '../components/bookExtract';
import ReviewsList from '../components/reviewsList';


const Downfall = () => {
  const data = booksMeta.Downfall;
  const PAGE = 'Downfall';
  const baseSiteUrl = 'https://authorseanallen.com';

  return (
    <Layout>
      <LeadingQuote
        title={data.title}
      />
      <Head title={PAGE} />
      <Helmet>
        <meta name="description"
          content="Title: Downfall,
          Series: The D-Evolution,
          Series number: Book 5,
          Author: Sean Allen,
          Publisher: Vintage Six Media,
          Genres: Space Opera, Fantasy, Science Fiction" />
        <link rel="canonical" href={`${baseSiteUrl}/${PAGE}`} />
      </Helmet>
      <Hero
        title={data.title}
        image={data.image}
        imgPosition={data.imgPosition}
        textPosition={data.textPosition}
        blurb={data.blurb}
        releaseDate={data.releaseDate}
        nextBookName={data.nextBookName}
        nextBookImage={data.nextBookImage}
        nextInSeries={data.nextInSeries}
        amazonUSLink={data.amazonUSLink}
        amazonUKLink={data.amazonUKLink}
        appleLink={data.appleLink}
        koboLink={data.koboLink}
        bnHCLink={data.bnHCLink}
        container="Downfall"
      />
      {/* <BookExtract extract={data.extract} /> */}
      {/* <BuyBook title={data.title} /> */}
      <ReviewsList title={data.title} />
    </Layout>
  )
}

export default Downfall;